import language from "./../lang";

export class AppBasket {

    constructor() {
        // данные полученные из подгрузки
        this._token = $('meta[name="csrf-token"]').attr('content');
        this.lang = $('html').attr('lang');

        this.prefixUrl = '';
        // определить принадлежность языка и URL
        if (window._locale != window._defaultLocale) {
            this.prefixUrl = '/' + window._locale;
        }

        this.urlBase = this.prefixUrl + '/cart';
        this.urlCount = this.urlBase + '/count/';
        this.urlAdd = this.urlBase + '/add/';
        this.urlDelete = this.urlBase + '/delete/';
        this.urlEdit = this.urlBase + '/update/';
        this.urlDestroy = this.urlBase + '/destroy/';

        this.total = {};

        this.events();
    }

    events() {
        let context = this;
        $(document).on('click', '[data-action="add_basket"]', function () {
            let product = parseInt($(this).attr('data-product-id'));
            let variant = parseInt($(this).attr('data-variant-id'));
            let quantity = parseInt($('[data-container="quantity"] [data-element="quantity"]').val());

            context.addProduct($(this), product, variant, quantity);

            return false;
        });

        $(document).on('click', '[data-action="delete"]', function () {
            let id = $(this).attr('data-id');

            context.deleteProduct($(this), id);

            return false;
        });

        $(document).on('click', '[data-action="minus"], [data-action="plus"]', function(){
            var arrow = $(this);
            let container = $(this).closest('.quantity');
            let id = $(this).attr('data-id');
            let quantity = parseInt($('input[data-id="'+ id +'"]').val());
            
            if (arrow.hasClass('plus')) {
                quantity++;
            } else if (arrow.hasClass('minus')) {
                if (quantity != 1) {
                    quantity--;
                }
            }

            context.updateProduct(container.closest('.order-item'), id, quantity);
            return false;
        });

        $(document).on('change', '#mini_cart_head .price-input', function(){
            let container = $(this).closest('.quantity');
            let id = $(this).attr('data-id');
            let quantity = parseInt($(this).val());
            
            context.updateProduct(container.closest('.order-item'), id, quantity);
            return false;
        });
    
        context.setCountSmall();
    }

    getFormData($form) {
        var unindexed_array = $form.serializeArray();
        var indexed_array = {};

        $.map(unindexed_array, function (n, i) {
            indexed_array[n['name']] = n['value'];
        });

        return indexed_array;
    }

    deleteProduct(_this, rowId) {
        let context = this;
        $('.preloader_container').addClass('d-show');
        $.ajax({
            type: 'DELETE',
            url: context.urlDelete+rowId+'/',
            dataType: 'json',
            data: {
                id: rowId,
                _token: context._token,
                lang: context.lang
            },
            success: function (response) {
                $('.preloader_container').removeClass('d-show');
                context.total = response.totals;
                context.setCountSmall();
            }
        });
    }

    addProduct(_this, productId, offerId, quantity) {
        let context = this;
        $('.preloader_container').addClass('d-show');
        axios({
            method: 'post',
            url: context.urlAdd,
            contentType: 'application/json',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': context._token
            },
            data: {
                product_id: productId,
                variant_id: offerId,
                quantity: quantity > 0 ? quantity : 1,
                _token: context._token,
                lang: context.lang
            },
        }).then(response => {
            $('.preloader_container').removeClass('d-show');
            context.setCountSmall();
            if(response.status == 200){
                $('[data-target="#modal_add_product_to_basket"]').trigger('click');
            } else if(response.status == 201) {
                
            }
        }).catch(err => {
            $('.preloader_container').removeClass('d-show');
            console.log(err);
        });
    }

    updateProduct(_this, rowId, quantity) {
        let context = this;

        $('.preloader_container').addClass('d-show');
        axios({
            method: 'put',
            url: context.urlEdit,
            contentType: 'application/json',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': context._token
            },
            data: {
                rowId: rowId,
                quantity: quantity,
                _token: context._token,
                lang: context.lang
            },
        }).then(response => {
            context.setCountSmall();
        }).catch(err => {
            $('.preloader_container').removeClass('d-show');
            console.log(err);
        });
    }

    /**
     * Обновление коризны до актуальных данных
     */
    setCountSmall() {
        // обновление малой корзины
        let context = this;

        context.items = {};

        axios({
            method: 'get',
            url: this.urlCount,
            contentType: 'application/json',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': context._token
            },
            data: {
                _token: context._token,
                lang: context.lang
            },
        }).then(response => {
            context.total = response.data.totals;
            $('[data-id="panelBasketCount"]').attr('data-count', parseInt(response.data.count));
            $('#mini_cart_head').html(response.data.view);
            $('.preloader_container').removeClass('d-show');
        }).catch(err => {
            console.log(err);
        });
    }

    setTotal() {
        let sum = this.total.price.toFixed(2);

        $('.your-order-wrapper .form-footer').find('.total-price').text(language('basket.sum_title')+' '+sum+' грн.');

        // if (parseInt(this.total.quantity) == 0) {
        //     document.location = this.prefixUrl + '/cart/';
        // }
    }
}