export class Accordion {

    /**
    *
    * @param {*Родительский блок} parent
    * @returns высота всех потомков
    */
    getChildrenHeight(parent) {
        let childrenHeight = 0;
        let children = $(parent).children();
        children.each((i, el) => {
            childrenHeight += $(el).outerHeight();
        })
        return childrenHeight;
    };


    accordeonAnimation(contentWrapper, height, action) {
        if (action == 'show') {
            contentWrapper.animate({
                height: height + 'px'
            }, 100)
        } else if (action == 'hide') {
            contentWrapper.animate({
                height: height + 'px'
            }, 100)
        }
    };

    accordeonToggler(el) {
        let acoordeonItem = $(el).closest('.accordion-item');
        let acoordeonContentWrapper = $(el).siblings('.flex-wrapper');
        let acordeonContentHeight = this.getChildrenHeight(acoordeonContentWrapper)

        if(acoordeonItem.hasClass('visible')){
            this.accordeonAnimation(acoordeonContentWrapper, 0, 'hide')
            acoordeonItem.removeClass('visible')
            return;
        }

        $(el).toggleClass('show');

        if ($(el).hasClass('show')) {
            acoordeonContentWrapper.show();
            //this.accordeonAnimation(acoordeonContentWrapper, acordeonContentHeight, 'show')
        } else {
            acoordeonContentWrapper.hide();
            //this.accordeonAnimation(acoordeonContentWrapper, 0, 'hide')
        }
    };
}